<template>
<v-container>
  <v-row justify="center">
    <v-col :cols="11" :md="8">
      <h1>Thank You</h1>
      <br/>
      <p>We're all set to impress. <br/> <br/>Please provide feedback at end of demo</p>
      <v-container>
        <v-row v-for="(question, i) in questionList" :key="'question'+i" align="center">
          <v-col :cols="12" :md="6">
            {{ question.name }}
          </v-col>
          <v-col :cols="12" :md="6">
            <vue-feedback-reaction v-model="$attrs.value.feedback[i]" :labels="reactionLabels" />
          </v-col>
        </v-row>
      </v-container>
      <v-textarea v-model="$attrs.value.comments" outlined
                  placeholder="Additional comments or suggestions?"></v-textarea>
      <v-btn class="mr-5" @click="$emit('back')">Back</v-btn>
      <v-btn @click="$emit('submit')">Submit Feedback</v-btn>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import { VueFeedbackReaction } from 'vue-feedback-reaction';
import { db } from '../../db';

export default {
  name: 'Thankyou',
  components: {
    VueFeedbackReaction,
  },
  data: () => ({
    questionList: [],
    reactionLabels: [
      'Very Poor',
      'Poor',
      'Fair',
      'Good',
      'Very Good',
    ],
  }),
  firestore: {
    questionList: db.collection('question'),
  },
};
</script>

<style scoped>

</style>
