<template>
<v-container>
  <h1>Register</h1>
  <v-row align="center" justify="center" class="my-5">
    <v-col :cols="11" :md="8">
      <v-form ref="form" lazy-validation>
        <v-text-field
          outlined
          v-model="$attrs.value.name"
          :counter="10"
          :rules="nameRules"
          label="Name"
          required
        ></v-text-field>

        <v-text-field
          outlined
          v-model="$attrs.value.email"
          :rules="emailRules"
          label="Email"
          required
        ></v-text-field>

        <br/>
        <h4>General Interests (Optional)</h4>
        <label style="color: gray; font-style: italic">Tick all that apply</label>
        <v-row :gutter="20" class="mb-5 mt-2">
          <v-col :cols="12" :sm="6"
                 style="padding: 0 12px"
                 v-for="interest in interestList"
                 :key="interest.name">
            <v-checkbox
              v-model="$attrs.value.interests"
              :label="interest.name"
              :value="interest.name"
              dense
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-btn class="mt-10" @click="next">
            Next</v-btn>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import { db } from '../../db';

export default {
  name: 'Register',
  data: () => ({
    nameRules: [
      (v) => !!v || 'Name is required',
      (v) => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    interestList: [
      {
        name: 'Virtual Reality',
        value: 'vr',
      },
      {
        name: '3D Models',
        value: '3dModel',
      },
      {
        name: 'Gamification (Web)',
        value: 'gamification',
      },
      {
        name: 'Bespoke Builds',
        value: 'bespokeBuilds',
      },
      {
        name: 'AR',
        value: 'ar',
      },
      {
        name: 'Sensor Touch Points',
        value: 'sensorTouch',
      },
      {
        name: 'QR Codes',
        value: 'qrCode',
      },
      {
        name: 'Social Media Analytics',
        value: 'mediaAnalytics',
      },
    ],
  }),
  methods: {
    next() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.$emit('next');
      }
    },
  },
  firestore: {
    interestList: db.collection('technology'),
  },
};
</script>

<style scoped>

</style>
