<template>
  <v-container>
    <h1 class="mb-5">Select Your Demos</h1>
    <v-row :gutter="20">
      <v-col :cols="12" :md="4">
        <v-data-table
          v-model="$attrs.value.demos"
          :headers="headers"
          :items="projects"
          :single-expand="true"
          :expanded.sync="expanded"
          item-key="name"
          show-expand
          show-select
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:header.name="{ header }">
            <span style="color: #ec529e">{{ header.text }}</span>
          </template>
          <template v-slot:header.data-table-select="{}">
            Demo?
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-3">
              <h3 style="color: #ec529e">Project Description</h3>
              {{ item.challenge }}
            </td>
          </template>
        </v-data-table>
      </v-col>
      <v-col :cols="12" :md="8" class="d-flex justify-center">
        <p v-if="expanded.length === 0">
          Click the dropdown icons to view more about each project.
          Tick the projects you'd like to demo.
          <br/>
          At the end we'll send you an email with a PDF of everything you tried today!
        </p>
        <v-card style="width: 100%" v-else>
          <v-card-title>{{ expanded[0].name }}</v-card-title>
          <v-divider color="#ec529e"></v-divider>
          <v-card-text>
<!--            <h3 style="color: black" v-if="expanded[0].technologies">Technology Involved</h3>-->
<!--            <v-chip-->
<!--              class="ma-1"-->
<!--              outlined-->
<!--              v-for="(tech, i) in expanded[0].technologies"-->
<!--              :key="'technology'+i"-->
<!--            >-->
<!--              {{ tech }}-->
<!--            </v-chip>-->
            <v-img :src="expanded[0].image"
                   aspect-ratio="1.77778"
                   class="my-2" style="object-fit: cover"/>
            <v-expansion-panels accordion multiple :value="[0,1,2]">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Solution
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  {{ expanded[0].solution }}
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="expanded[0].result">
                <v-expansion-panel-header>
                  Result
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ul v-if="expanded[0].result && Array.isArray(expanded[0].result)">
                    <li v-for="(result, i) in expanded[0].result" :key="i">
                      {{ result }}
                    </li>
                  </ul>
                  <span v-else>{{ expanded[0].result }}</span>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="expanded[0].opportunities">
                <v-expansion-panel-header>
                  Opportunities
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <span v-if="expanded[0].opportunitySummary">
                    {{ expanded[0].opportunitySummary }}</span>
                  <ul>
                    <li v-for="(o, i) in expanded[0].opportunities" :key="i">
                      {{ o }}
                    </li>
                  </ul>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="end" class="mt-10">
      <v-btn class="mr-2" @click="$emit('back')">
        Back
      </v-btn>
      <v-btn @click="$emit('next')">
        Next
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { db } from '../../db';

export default {
  name: 'SelectDemo',
  data: () => ({
    expanded: [],
    projects: [
      {
        name: 'Oracle VR Sail GP',
        challenge: 'Hello I\'m awesome. Give me projects.',
        technologies: ['VR', '3D Modelling'],
        image: 'https://www.sailing.org/images/news/ML2_5137_rdax_1200x800_90.jpg',
        solution: 'Showcase Oracle at Gartner Symposium to show how much money they have and steal everyone’s data to spam them with email marketing and onboard as a client as a diabolical evil plot',
        result: ['Tracked foot traffic and automatically linked to their database, capturing over 3000 detais and delivering phenomenal results'],
        opportunities: ['Technology is easily configurable to web games and other cool things.'],
      },
    ],
    headers: [
      {
        text: '2DM Projects',
        value: 'name',
      },
    ],
  }),
  firestore: {
    projects: db.collection('project'),
  },
};
</script>

<style scoped>

</style>
