<template>
  <v-container class="my-5">
    <register v-if="step === 0" v-model.lazy="form" @next="next" />
    <select-demo v-else-if="step === 1" v-model.lazy="form" @back="back" @next="next" />
    <thankyou v-else-if="step === 2" v-model.lazy="form" @back="back" @submit="submit" />
    <v-container v-else-if="step === 3" class="text-center">
      <h1>Ready to Partner? <br/> Let's Discuss!</h1>
      <br/>
      <br/>
      <p>P.S. Your personalised PDF is sitting in your inbox now.</p>
    </v-container>
    <v-overlay :value="loading" :opacity="0.6">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import Register from '@/components/Register.vue';
import SelectDemo from '@/components/SelectDemo.vue';
import Thankyou from '@/components/Thankyou.vue';
import '../assets/font/Arvo-Regular-normal';
import '../assets/font/Arvo-Bold-bold';
import { jsPDF } from 'jspdf';
import footerImage from '@/assets/image/footer.jpg';
import logoDark from '@/assets/image/logo_dark.png';
import contentImage from '@/assets/image/content.jpg';
import blueBg from '@/assets/image/background_blue.jpg';
import blueBgFull from '@/assets/image/bg_blue_full.jpg';
import logoLarge from '@/assets/image/logo_lg.png';
// import shareBtn from '@/assets/image/share.jpg';
import axios from 'axios';
import { db } from '../../db';

export default {
  name: 'NewSession',
  components: { Thankyou, SelectDemo, Register },
  data: () => ({
    screen: {},
    form: {
      name: '',
      email: '',
      interests: [],
      demos: [],
      feedback: [],
      comments: '',
    },
    step: 0,
    loading: false,
    snackbar: false,
    snackText: '',
  }),
  created() {
    if (this.$route.query.screenId) {
      this.getSessionData();
    }
  },
  methods: {
    getSessionData() {
      db.collection('screen')
        .doc(this.$route.query.screenId)
        .get()
        .then((snapshot) => {
          this.screen = snapshot.data();
          this.form = snapshot.data();
        });
    },
    updateSession() {
      db.collection('screen')
        .doc(this.$route.query.screenId)
        .update(this.form)
        .then(() => {
          this.getSessionData();
        });
    },
    next() {
      if (this.step < 2) {
        this.step += 1;
      }
    },
    back() {
      if (this.step > 0) {
        this.step -= 1;
      }
    },
    async submit() {
      this.loading = true;
      await this.createPdf();
      if (this.$route.query.screenId) {
        db.collection('screen')
          .doc(this.$route.query.screenId)
          .update(this.form)
          .then(() => {
            this.step = 3;
          });
      } else {
        db.collection('screen').add(this.form).then(() => {
          this.getSessionData();
          this.step = 3;
        });
      }
      this.loading = false;
    },
    getImageDimensions(image) {
      return new Promise((resolve) => {
        const i = new Image();
        // eslint-disable-next-line
        i.onload = function () {
          resolve({ w: i.width, h: i.height, image: i });
        };
        i.src = image;
      });
    },
    uploadPdf(pdf) {
      return new Promise((resolve) => {
        const currentTime = new Date();
        const month = currentTime.getMonth() + 1;
        const year = currentTime.getFullYear();
        const dateNowToString = Date.now().toString();
        const key = `pdfs/${year}/${month}/${dateNowToString}2dm_Case_Study_&_Overview_for_${this.form.name}.pdf`;
        axios.post('https://wjlzx3ue99.execute-api.ap-southeast-2.amazonaws.com/default/2dm-lab-uploader',
          {
            fileName: key,
          })
          .then((res) => {
            console.log(res);
            axios.put(res.data, pdf)
              .then((r) => {
                console.log(r);
                axios.post('https://550c9p29pf.execute-api.ap-southeast-2.amazonaws.com/default/2dm-lab-mailer',
                  {
                    recipient: this.form.email,
                    url: `https://2dm-lab.s3.amazonaws.com/${key}`,
                  }).finally(() => {
                  resolve();
                });
              });
          }).catch(() => {
            this.snackText = 'An error occurred while preparing your pdf';
            this.snackbar = true;
            resolve();
          });
      });
    },
    toDataUrl(url) {
      return new Promise((resolve) => {
        const xhr = new XMLHttpRequest();
        /* eslint-disable */
        xhr.onload = function () {
          const reader = new FileReader();
          reader.onloadend = function () {
            resolve(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        /* eslint-enable */
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
      });
    },
    async createPdf() {
      /* eslint-disable */
      var doc = new jsPDF('p', 'mm', 'a4');
      let pageNo = 0
      /* eslint-enable */
      const addTextCentre = (text, y) => {
        const textWidth = (doc.getStringUnitWidth(text) * doc.internal.getFontSize())
          / doc.internal.scaleFactor;
        const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
        doc.text(text, textOffset, y);
      };

      const addText = (text, x, y, font, size, color, align) => {
        if (font) doc.setFont(font);
        if (size) doc.setFontSize(size);
        if (color) {
          if (color === 'white') {
            doc.setTextColor(255, 255, 255);
          } else if (color === 'orange') {
            doc.setTextColor(255, 145, 77);
          }
        }
        if (align && align === 'right') {
          doc.text(text, x, y, { align: 'right' });
        } else {
          doc.text(text, x, y);
        }
      };

      const addPageNo = () => {
        doc.setFontSize(14);
        doc.setTextColor(255, 255, 255);
        doc.text(pageNo.toString(), 200, 290);
      };

      // cover
      doc.setFillColor(0);
      doc.rect(0, 0, 210, 297, 'F');
      doc.setFont('Arvo-Regular');
      doc.setTextColor(255, 255, 255);
      doc.setFontSize(40);
      addTextCentre('2dm.digital', 45);
      doc.setFontSize(28);
      addTextCentre('Case Studies & Demo', 72);
      addTextCentre('Overview', 86);
      addTextCentre('Prepared for', 128);
      addTextCentre(this.form.name, 142);
      doc.setFontSize(14);
      addTextCentre('2dm.net.au', 178);
      addTextCentre('+61 2 8004 5377', 185);
      const footer = await this.getImageDimensions(footerImage);
      doc.addImage(footer.image, 'JPEG', 0, 188, 210, 109);

      // table of contents
      // pageNo += 1;
      doc.addPage('a4', 'p');
      doc.rect(0, 0, 210, 297, 'F');
      const logo = await this.getImageDimensions(logoDark);
      doc.addImage(logo.image, 'JPEG', 8, 10, 36, (logo.h / logo.w) * 36);
      const content = await this.getImageDimensions(contentImage);
      let yIdx = 10;
      doc.addImage(content.image, 'JPEG', 160, yIdx, 48, (content.h / content.w) * 48);
      yIdx = yIdx + (content.h / content.w) * 48 + 22;
      // Start numbering pages after full table of contents as it's hard to know how many pages it's
      // going to take
      addText('1', 10, yIdx, 'Arvo-Bold', 32, 'orange');
      addText('About Us', 26, yIdx, 'Arvo-Regular', 32, 'white');
      yIdx += 16;
      let page = 2;
      for (let i = 0; i < this.form.demos.length; i += 1) {
        if (yIdx < 250) {
          addText(page.toString(), 10, yIdx, 'Arvo-Bold', 32, 'orange');
          if (210 - doc.getTextWidth(`Case Study: ${this.form.demos[i].name}`) - 30 > 0) {
            addText(`Case Study: ${this.form.demos[i].name}`, 26, yIdx, 'Arvo-Regular', 32, 'white');
          } else {
            addText('Case Study:', 26, yIdx, 'Arvo-Regular', 36, 'white');
            yIdx += 16;
            addText(this.form.demos[i].name, 26, yIdx);
          }
        } else {
          doc.setFontSize(14);
          addTextCentre('Email: info@2dm.net.au          Landline:  +61 2  8004 5377', 290);
          // addPageNo();

          // pageNo += 1;
          yIdx = 10;
          doc.addPage('a4', 'p');
          doc.rect(0, 0, 210, 297, 'F');
          doc.addImage(logo.image, 'JPEG', 8, 10, 36, (logo.h / logo.w) * 36);
          doc.addImage(content.image, 'JPEG', 160, yIdx, 48, (content.h / content.w) * 48);
          yIdx = yIdx + (content.h / content.w) * 48 + 22;
          addText(page.toString(), 10, yIdx, 'Arvo-Bold', 32, 'orange');
          if (210 - doc.getTextWidth(`Case Study: ${this.form.demos[i].name}`) - 30 > 0) {
            addText(`Case Study: ${this.form.demos[i].name}`, 26, yIdx, 'Arvo-Regular', 32, 'white');
          } else {
            addText('Case Study:', 26, yIdx, 'Arvo-Regular', 36, 'white');
            yIdx += 16;
            addText(this.form.demos[i].name, 26, yIdx);
          }
        }
        yIdx += 16;
        // replace with no. pages for that case study if changed from 2
        page += 2;
      }
      const headings = ['2DM Capabilities', 'Let\'s Partner', 'Contact Us'];
      headings.forEach((el) => {
        if (yIdx < 250) {
          addText(page.toString(), 10, yIdx, 'Arvo-Bold', 32, 'orange');
          addText(el, 26, yIdx, 'Arvo-Regular', 32, 'white');
        } else {
          doc.setFontSize(14);
          addTextCentre('Email: info@2dm.net.au          Landline:  +61 2  8004 5377', 290);
          yIdx = 10;
          doc.addPage('a4', 'p');
          doc.rect(0, 0, 210, 297, 'F');
          doc.addImage(logo.image, 'JPEG', 8, 10, 36, (logo.h / logo.w) * 36);
          doc.addImage(content.image, 'JPEG', 160, yIdx, 48, (content.h / content.w) * 48);
          yIdx = yIdx + (content.h / content.w) * 48 + 22;
          addText(page.toString(), 10, yIdx, 'Arvo-Bold', 32, 'orange');
          addText(el, 26, yIdx, 'Arvo-Regular', 32, 'white');
        }
        yIdx += 16;
        page += 1;
      });
      doc.setFontSize(14);
      addTextCentre('Email: info@2dm.net.au          Landline:  +61 2  8004 5377', 290);
      // addPageNo();

      const newPage = () => {
        pageNo += 1;
        yIdx = 10;
        doc.addPage('a4', 'p');
        doc.rect(0, 0, 210, 297, 'F');
        doc.setFontSize(14);
        addTextCentre('Email: info@2dm.net.au          Landline:  +61 2  8004 5377', 290);
        addPageNo();
      };

      // about us
      pageNo += 1;
      yIdx = 10;
      doc.addPage('a4', 'p');
      doc.rect(0, 0, 210, 297, 'F');
      doc.addImage(logo.image, 'JPEG', 8, yIdx, 36, (logo.h / logo.w) * 36);
      yIdx += 50;
      doc.setFontSize(40);
      doc.setFont('Arvo-Bold');
      doc.setTextColor(255, 255, 255);
      addTextCentre('About Us', yIdx);
      yIdx += 20;
      doc.setFont('Arvo-Regular');
      doc.setFontSize(18);
      doc.setLineHeightFactor(1.25);
      doc.text('We\'re thinkers, tinkerers, collaborators \nand creators.\n \n '
        + 'We believe 2d.ay is always different from '
        + '\nyesterday and that 2m.orrow will provide '
        + '\nsomething new. It’s an approach that '
        + '\nsparks our curiosity and passion about '
        + '\ntechnology. An insight driven curiosity that '
        + '\nput humans at the center of what we do.'
        + '\n \n Our mission is to provide the best digital \nsolutions available.', 40, yIdx);
      const bgBlue = await this.getImageDimensions(blueBgFull);
      doc.addImage(bgBlue.image, 'JPEG', 50, 200, 160, 90);
      doc.setFontSize(14);
      addTextCentre('Email: info@2dm.net.au          Landline:  +61 2  8004 5377', 290);
      addPageNo();

      const addBodyText = (text, x, y, heading, align) => {
        if (290 - y > 11) {
          if (align && align === 'right') {
            doc.text(text, x, y, {
              align: 'right',
            });
            yIdx += 9;
          } else {
            doc.text(text, x, y);
            yIdx += 9;
          }
        } else {
          newPage();
          doc.addImage(logo.image, 'JPEG', 8, yIdx, 36, (logo.h / logo.w) * 36);
          yIdx = 46;
          if (heading) {
            addText(heading, 11, yIdx, 'Arvo-Bold', 40, 'orange');
            yIdx += 14;
          }
          doc.setFont('Arvo-Regular');
          doc.setTextColor(255, 255, 255);
          doc.setFontSize(18);
          if (align && align === 'right') {
            doc.text(text, x, yIdx, {
              align: 'right',
            });
            yIdx += 9;
          } else {
            doc.text(text, x, yIdx);
            yIdx += 9;
          }
        }
      };

      const addCaseListSection = (section, heading, demo, array, align) => {
        if (290 - yIdx > 27) {
          if (align === 'right') {
            addText(heading, 210 - 13, yIdx, 'Arvo-Bold', 20, 'orange', align);
          } else {
            addText(heading, 13, yIdx, 'Arvo-Bold', 20, 'orange');
          }
          yIdx += 14;
          doc.setFontSize(18);
          doc.setTextColor(255, 255, 255);
          doc.setFont('Arvo-Regular');
          if (array) {
            if (section === 'opportunities' && demo.opportunitySummary) {
              const text = doc.splitTextToSize(demo.opportunitySummary, 160);
              text.forEach((t) => addBodyText(t, 22, yIdx, demo.name));
            }
            const results = demo[section];
            // eslint-disable-next-line
            results.forEach((t) => {
              const texts = doc.splitTextToSize(t, 160);
              // eslint-disable-next-line
              texts.forEach((s, i) => {
                if (i === 0) {
                  addBodyText(`• ${s}`, 26, yIdx, demo.name);
                } else {
                  addBodyText(s, 26 + doc.getTextWidth('• '), yIdx, demo.name);
                }
              });
            });
          } else {
            const result = doc.splitTextToSize(demo[section], 160);
            // eslint-disable-next-line
            if (align === 'right') {
              result.forEach((t) => addBodyText(t, 210 - 22, yIdx, demo.name, align));
            } else {
              result.forEach((t) => addBodyText(t, 22, yIdx, demo.name));
            }
          }
        } else {
          newPage();
          doc.addImage(logo.image, 'JPEG', 8, yIdx, 36, (logo.h / logo.w) * 36);
          yIdx = 46;
          addText(demo.name, 11, yIdx, 'Arvo-Bold', 40, 'orange');
          yIdx += 20;
          if (align === 'right') {
            addText(heading, 210 - 13, yIdx, 'Arvo-Bold', 20, 'orange', align);
          } else {
            addText(heading, 13, yIdx, 'Arvo-Bold', 20, 'orange');
          }
          yIdx += 14;
          doc.setFontSize(18);
          doc.setTextColor(255, 255, 255);
          doc.setFont('Arvo-Regular');
          if (array) {
            const results = demo[section];
            // eslint-disable-next-line
            results.forEach((t) => {
              const texts = doc.splitTextToSize(t, 160);
              // eslint-disable-next-line
              texts.forEach((s, i) => {
                if (i === 0) {
                  addBodyText(`• ${s}`, 26, yIdx, demo.name);
                } else {
                  addBodyText(s, 26 + doc.getTextWidth('• '), yIdx, demo.name);
                }
              });
            });
          } else {
            const result = doc.splitTextToSize(demo[section], 160);
            // eslint-disable-next-line
            if (align === 'right') {
              result.forEach((t) => addBodyText(t, 210 - 22, yIdx, demo.name, align));
            } else {
              result.forEach((t) => addBodyText(t, 22, yIdx, demo.name));
            }
          }
        }
        yIdx += 14;
      };

      // case studies
      /* eslint-disable no-await-in-loop */
      for (let i = 0; i < this.form.demos.length; i += 1) {
        // first page
        newPage();
        doc.addImage(logo.image, 'JPEG', 8, yIdx, 36, (logo.h / logo.w) * 36);
        yIdx = 46;
        addText(this.form.demos[i].name, 11, yIdx, 'Arvo-Bold', 40, 'orange');
        const image = await this.toDataUrl(this.form.demos[i].image);
        const pdfImg = await this.getImageDimensions(image);
        yIdx += 9;
        doc.addImage(pdfImg.image, 'JPEG', 0, yIdx, 210, (pdfImg.h / pdfImg.w) * 210);
        yIdx += (pdfImg.h / pdfImg.w) * 210 + 12;
        if (this.form.demos[i].challenge) addCaseListSection('challenge', 'The Challenge', this.form.demos[i]);
        if (this.form.demos[i].solution) addCaseListSection('solution', 'The Solution', this.form.demos[i], false, 'right');
        if (this.form.demos[i].result) addCaseListSection('result', 'Results', this.form.demos[i], Array.isArray(this.form.demos[i].result));
        if (this.form.demos[i].opportunities) addCaseListSection('opportunities', 'Opportunities/Alternate Ways of Use', this.form.demos[i], true);
      }
      /* eslint-enable no-await-in-loop */

      // capabilities
      newPage();
      const headerBlue = await this.getImageDimensions(blueBg);
      doc.addImage(headerBlue.image, 'JPEG', 56, 0, 154, (headerBlue.h / headerBlue.w) * 154);
      doc.addImage(logo.image, 'JPEG', 8, yIdx, 36, (logo.h / logo.w) * 36);
      yIdx = 46;
      addText('2DM Capabilities', 11, yIdx, 'Arvo-Bold', 40, 'orange');
      yIdx += 23;
      doc.setFont('Arvo-Regular');
      doc.setFontSize(18);
      doc.setTextColor(255, 255, 255);
      addTextCentre('We\'re a data driven creative agency that', yIdx);
      yIdx += 9;
      addTextCentre('makes magic happen.', yIdx);
      yIdx += 9;
      addTextCentre('Our capabilities include:', yIdx);
      yIdx += 23;
      doc.setTextColor(255, 145, 77);
      doc.setFont('Arvo-Bold');
      addTextCentre('EXPERIENTIAL SERVICES', yIdx);
      doc.setTextColor(255, 255, 255);
      doc.setFont('Arvo-Regular');
      yIdx += 9;
      addTextCentre('• Applications', yIdx);
      yIdx += 9;
      addTextCentre('• VR / AR', yIdx);
      yIdx += 9;
      addTextCentre('• Digital Interactive', yIdx);
      yIdx += 9;
      addTextCentre('• Gamification / Edutainment', yIdx);
      yIdx += 9;
      addTextCentre('• Lead Capture', yIdx);
      yIdx += 9;
      addTextCentre('• Social Media Integration', yIdx);
      yIdx += 9;
      addTextCentre('• Content Creation', yIdx);
      yIdx += 9;
      addTextCentre('• IOT', yIdx);
      yIdx += 9;
      addTextCentre('• Robotics', yIdx);
      yIdx += 18;
      doc.setTextColor(255, 145, 77);
      doc.setFont('Arvo-Bold');
      addTextCentre('CLOUD SERVICES', yIdx);
      yIdx += 9;
      doc.setTextColor(255, 255, 255);
      doc.setFont('Arvo-Regular');
      addTextCentre('• Architecture', yIdx);
      yIdx += 9;
      addTextCentre('• DevOps', yIdx);
      yIdx += 9;
      addTextCentre('• Optimisation', yIdx);
      yIdx += 9;
      addTextCentre('• Application Management', yIdx);
      yIdx += 9;
      addTextCentre('• Information Security', yIdx);
      yIdx += 9;
      addTextCentre('• Hosting', yIdx);
      yIdx += 9;
      addTextCentre('• Testing', yIdx);

      // get started
      newPage();
      doc.addImage(headerBlue.image, 'JPEG', 56, 0, 154, (headerBlue.h / headerBlue.w) * 154);
      doc.addImage(logo.image, 'JPEG', 8, yIdx, 36, (logo.h / logo.w) * 36);
      yIdx = 46;
      addText('Let\'s Get Started', 11, yIdx, 'Arvo-Bold', 40, 'orange');
      yIdx += 26;
      doc.setFont('Arvo-Regular');
      doc.setFontSize(18);
      doc.setTextColor(255, 255, 255);
      addTextCentre('We\'re confident in exceeding your', yIdx);
      yIdx += 9;
      addTextCentre('expectations.', yIdx);
      yIdx += 9;
      yIdx += 9;
      addTextCentre('Book a 30 Minute Free Ideation Strategy call for', yIdx);
      yIdx += 9;
      addTextCentre('one of your projects. Be sure to include your', yIdx);
      yIdx += 9;
      addTextCentre('colleagues on the invite.', yIdx);
      yIdx += 9;
      yIdx += 9;
      addTextCentre('During your session we could explore:', yIdx);
      yIdx += 9;
      yIdx += 9;
      addTextCentre('• Building out a scope', yIdx);
      yIdx += 9;
      addTextCentre('• Answering your questions', yIdx);
      yIdx += 9;
      addTextCentre('• Technical Limitations', yIdx);
      yIdx += 9;
      addTextCentre('• Project commencement', yIdx);
      yIdx += 22;
      doc.setFillColor(254, 122, 89);
      doc.rect(28, yIdx, 154, 16, 'F');
      doc.setFillColor(0);
      yIdx += 10;
      addTextCentre('Schedule your online personal session', yIdx);
      doc.link(28, yIdx - 10, 154, 16, { url: 'mailto:hello@2dm.net.au' });

      // contact us
      newPage();
      const logoLg = await this.getImageDimensions(logoLarge);
      doc.addImage(logoLg.image, 'JPEG', 55, yIdx, 100, (logoLg.h / logoLg.w) * 100);
      yIdx += (logoLg.h / logoLg.w) * 100 + 20;
      doc.setFontSize(40);
      addTextCentre('CONTACT US', yIdx);
      yIdx += 18;
      doc.setFontSize(18);
      addTextCentre('info@2dm.net.au', yIdx);
      yIdx += 9;
      addTextCentre('+61 2 8004 5377', yIdx);
      yIdx += 27;
      // doc.save('2dm Case Study & Overview.pdf');
      const pdf = doc.output('blob');
      await this.uploadPdf(pdf);
    },
  },
};
</script>

<style scoped></style>
